import React from 'react';
import classNames from 'classnames';

import BaseScheduleCard from '../BaseScheduleCard/BaseScheduleCard';

import styles from './PreviousScheduleCard.module.scss';
import getTeamNameClassName from '../../../utils/getTeamNameClassName';

const PreviousScheduleCard = ({ circuit, date, name, round, season, time, winner }) => {
  const race = name.replace('Grand Prix', '');

  return (
    <div className={styles.wrapper}>
      <BaseScheduleCard
        circuit={circuit.name}
        date={date}
        delay={200}
        location={circuit.location.country}
        race={<span>
          {race}
          {race.length > 8 ? <br /> : null}
          Grand Prix
        </span>}
        showCountdown={false}
        subtitle={
          <>
            <span
              className={classNames(
                styles.driver,
                getTeamNameClassName(winner.team)
              )}
            >
              {winner.driver?.name}
            </span>{' '}
            wins the
          </>
        }
        time={time}
        title={<span className={styles.title}>Previous</span>}
        buttonText="Race Report"
        buttonUrl={`/season/${season}/${round}/`}
      />
    </div>
  );
};

export default PreviousScheduleCard;
