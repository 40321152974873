import React from 'react';

import BaseScheduleCard from '../BaseScheduleCard/BaseScheduleCard';

import styles from './NextScheduleCard.module.scss';
import BokehParticles from "../../BokehParticles/BokehParticles"

const getDate = (d: Date) => new Date(d).getDate();
const getMonth = (d: Date) =>
  new Date(d).toLocaleString('default', { month: 'long' });
const getTime = (d: Date, t: Date) => {
  const dt = new Date(d + 'T' + t);
  return dt.getHours() + ':' + dt.getMinutes();
};

const NextScheduleCard = ({ circuit, date, name, round, season, time }) => {
  const race = name.replace('Grand Prix', '');

  return (
    <div className={styles.wrapper}>
      <BaseScheduleCard
        circuit={circuit.name}
        date={date}
        location={circuit.location.country}
        race={<span>
          {race}
          {race.length > 10 ? <br /> : null}
          Grand Prix
        </span>}
        showCountdown={true}
        subtitle={`${getDate(date)} ${getMonth(date)} @ ${getTime(date, time)}`}
        time={time}
        title={<span className={styles.title}>Next</span>}
        buttonText="Preview Race"
        buttonUrl={`/season/${season}/${round}/`}
      />
    </div>
  );
};

export default NextScheduleCard;
