import React, { useEffect, useRef } from 'react';

import styles from './BokehParticles.module.scss';

const BokehParticles = ({ baseHue, lightMin, lightMax }) => {
  const wrapperRef = useRef(null);
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);

  useEffect(() => {
    let ch = 400;
    let cw = 400;
    let c1 = canvasRef1.current;
    let c2 = canvasRef2.current;
    let ctx1 = c1.getContext('2d');
    let ctx2 = c2.getContext('2d');
    let parts = [];
    let sizeBase;
    let opt;
    let count;
    let deviceHeight = 0;
    let deviceWidth = 0;

    const randomInt = (min, max) => Math.random() * (max - min) + min;

    function hsla(h, s, l, a) {
      return 'hsla(' + h + ',' + s + '%,' + l + '%,' + a + ')';
    }

    function create() {
      sizeBase = cw + ch;
      count = Math.floor(sizeBase * 0.3);
      opt = {
        radiusMin: 1,
        radiusMax: sizeBase * 0.04,
        blurMin: 10,
        blurMax: sizeBase * 0.04,
        hueMin: baseHue,
        hueMax: baseHue + 100,
        saturationMin: 10,
        saturationMax: 70,
        lightnessMin: lightMin || 0,
        lightnessMax: lightMax || 30,
        alphaMin: 0.1,
        alphaMax: 0.5,
      };
      ctx1.clearRect(0, 0, cw, ch);
      ctx1.globalCompositeOperation = 'lighter';
      while (count--) {
        let radius = randomInt(opt.radiusMin, opt.radiusMax);
        let blur = randomInt(opt.blurMin, opt.blurMax);
        let x = randomInt(0, cw);
        let y = randomInt(0, ch);
        let hue = randomInt(opt.hueMin, opt.hueMax);
        let saturation = randomInt(opt.saturationMin, opt.saturationMax);
        let lightness = randomInt(opt.lightnessMin, opt.lightnessMax);
        let alpha = randomInt(opt.alphaMin, opt.alphaMax);

        ctx1.shadowColor = hsla(hue, saturation, lightness, alpha);
        ctx1.shadowBlur = blur;
        ctx1.beginPath();
        ctx1.arc(x, y, radius, 0, Math.PI * 2);
        ctx1.closePath();
        ctx1.fill();
      }

      parts.length = 0;
      for (let i = 0; i < Math.floor((cw + ch) * 0.02); i++) {
        parts.push({
          radius: randomInt(1, sizeBase * 0.03),
          x: randomInt(0, cw),
          y: randomInt(0, ch),
          angle: randomInt(0, Math.PI * 2),
          vel: randomInt(0.1, 0.5),
          tick: randomInt(0, 10000),
        });
      }
    }

    function init() {
      resize();
      create();
      loop();
    }

    function loop() {
      requestAnimationFrame(loop);

      ctx2.clearRect(0, 0, cw, ch);
      ctx2.globalCompositeOperation = 'source-over';
      ctx2.shadowBlur = 0;
      ctx2.drawImage(c1, 0, 0);
      ctx2.globalCompositeOperation = 'lighter';

      let i = parts.length;
      ctx2.shadowBlur = 15;
      ctx2.shadowColor = '#fff';
      while (i--) {
        let part = parts[i];

        part.x += Math.cos(part.angle) * part.vel;
        part.y += Math.sin(part.angle) * part.vel;
        part.angle += randomInt(-0.05, 0.05);

        ctx2.beginPath();
        ctx2.arc(part.x, part.y, part.radius, 0, Math.PI * 2);
        ctx2.fillStyle = hsla(
          0,
          0,
          100,
          0.075 + Math.cos(part.tick * 0.02) * 0.05
        );
        ctx2.fill();

        if (part.x - part.radius > cw) {
          part.x = -part.radius;
        }
        if (part.x + part.radius < 0) {
          part.x = cw + part.radius;
        }
        if (part.y - part.radius > ch) {
          part.y = -part.radius;
        }
        if (part.y + part.radius < 0) {
          part.y = ch + part.radius;
        }

        part.tick++;
      }
    }

    function resize() {
      cw = c1.width = c2.width = wrapperRef.current.clientWidth
        ? wrapperRef.current.clientWidth / 1.5
        : 200;
      ch = c1.height = c2.height = wrapperRef.current.clientHeight
        ? wrapperRef.current.clientHeight / 1.5
        : 100;
      create();
    }

    window.addEventListener('resize', () => {
      if (deviceWidth !== window.outerWidth || deviceHeight !== window.outerHeight) {
        deviceHeight = window.outerHeight;
        deviceWidth = window.outerWidth;
        resize();
      }
    });

    init();
  }, []);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <canvas className={styles.canvas1} ref={canvasRef1}></canvas>
      <canvas className={styles.canvas2} ref={canvasRef2}></canvas>
    </div>
  );
};

export default BokehParticles;
