import React from 'react';
import classNames from 'classnames';

import Countdown from '../../Countdown/Countdown';
import PrimaryButton from '../../Button/PrimaryButton/PrimaryButton';
import Transition from '../../Transition/Transition';

import styles from './BaseScheduleCard.module.scss';

type Props = {
  circuit: string;
  date: Date;
  raceName: string;
  round: string;
  season: string;
  time: Date;
  title: string;
};

const BaseScheduleCard: React.FunctionComponent<Props> = ({
  buttonText,
  buttonUrl,
  circuit,
  date,
  delay = 0,
  location,
  race,
  showCountdown,
  subtitle,
  time,
  title,
}) => {
  return (
    <div className={styles.content}>
      <Transition delay={delay} duration={400} entryType={['fadeIn', 'slideUp']}>
        <h1 className={styles.title}>{title}</h1>
      </Transition>

      <div className={styles.container}>
        <Transition delay={delay} duration={400} entryType={['fadeIn', 'slideUp']}>
          <h2 className={styles.subtitle}>{subtitle}</h2>
          <h1 className={styles.race}>{race}</h1>
          <h2 className={styles.detail}>
            <span className={styles.circuit}>{circuit}, </span>
            <span className={styles.country}>{location}</span>
          </h2>
        </Transition>

        {showCountdown ? (
          <div className={styles.countdown}>
            <Transition delay={delay + 600} duration={400} entryType={['fadeIn']}>
              <Countdown date={date} time={time} />
            </Transition>
          </div>
        ) : null}
      </div>
      <Transition delay={delay + 0} duration={400} entryType={['fadeIn', 'slideUp']}>
        <PrimaryButton text={buttonText} url={buttonUrl} />
      </Transition>
    </div>
  );
};

export default BaseScheduleCard;
