import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';

import PageLayout from '../components/PageLayout/PageLayout';
import NextScheduleCard from '../components/ScheduleCard/NextScheduleCard/NextScheduleCard';
import PreviousScheduleCard from '../components/ScheduleCard/PreviousScheduleCard/PreviousScheduleCard';
import SEO from '../components/seo';
import mergeDataSources from '../utils/mergeDataSources';
import SeasonLeaderBoard from '../components/SeasonLeaderBoard/SeasonLeaderBoard';
import Wrapper from '../components/Wrapper/Wrapper';
import SeasonScoreCard from '../components/ScoreCard/SeasonScoreCard/SeasonScoreCard';
import Title from '../components/Title/Title/Title';
import PrimaryButton from '../components/Button/PrimaryButton/PrimaryButton';
import SubTitle from '../components/Title/SubTitle/SubTitle';
import BokehParticles from '../components/BokehParticles/BokehParticles';
import StatBlock from '../components/StatBlock/StatBlock/StatBlock';

type DataProps = {
  schedule: {
    nodes: Array<{
      Races: Race[];
    }>;
  };
  standings: {
    nodes: Array<StandingsLists>;
  };
};

type Race = {
  Circuit: {
    circuitName: string;
    circuitId: string;
    Location: {
      country: string;
      locality: string;
    };
  };
  date: Date;
  raceName: string;
  round: string;
  season: string;
  time: Date;
};

type StandingsLists = {
  round: string;
  season: string;
  ConstructorStandings?: ConstructorStandings;
  DriverStandings?: DriverStandings;
};

type Constructor = {
  constructorId: string;
  name: string;
  nationality: string;
};

type ConstructorStandings = {
  position: string;
  positionText: string;
  points: string;
  wins: string;
  Constructor: Constructor;
};

type DriverStandings = {
  position: string;
  positionText: string;
  points: string;
  wins: string;
  Constructors: Array<Constructor>;
  Driver: {
    driverId: string;
    permanentNumber: string;
    code: string;
    givenName: string;
    familyName: string;
    dateOfBirth: Date;
    nationality: string;
  };
};

const addDriverInfo = (race, drivers) => {
  if (!race.winner?.driver?.name) {
    race.winner.driver = drivers.find(
      (driver) => driver.slug === race.winner.driver
    );
  }
  return race;
};

const IndexPage: React.FunctionComponent<PageProps<DataProps>> = ({
  data,
  pageContext,
}) => {
  const {
    allDrivers: { nodes: drivers },
    allDriverStandings: { nodes: driverStandings },
    allRaces: { nodes: races },
    allTeams: { nodes: teams },
    allTeamStandings: { nodes: teamStandings },
    lastRace: { nodes: lastRace },
    nextRace: { nodes: nextRace },
    winsDriverStat: { nodes: winsDriverStat },
    winsTeamStat: { nodes: winsTeamStat },
  } = data;

  const lastRaceInfo = addDriverInfo(lastRace[0], drivers);
  const nextRaceInfo = nextRace[0];

  const getStandings = (standings, round, season) =>
    standings.filter(
      (standing) => standing.round == round && standing.season === season
    );

  const latestDriverStandings = mergeDataSources(
    getStandings(driverStandings, lastRaceInfo.round, lastRaceInfo.season),
    ['driver', 'team'],
    [drivers, teams]
  );

  const latestTeamStandings = mergeDataSources(
    getStandings(teamStandings, lastRaceInfo.round, lastRaceInfo.season),
    ['team'],
    [teams]
  );

  return (
    <PageLayout>
      <SEO title="Home" />

      <Wrapper>
        <BokehParticles baseHue={150} lightMin={10} lightMax={30} />
        <NextScheduleCard {...nextRaceInfo} />
        <PreviousScheduleCard {...lastRaceInfo} />
      </Wrapper>

      <Wrapper alignment="center" isContainer={true}>
        <Title>{pageContext.currentSeason} Season So Far</Title>

        <SubTitle>Race Winners</SubTitle>

        <SeasonScoreCard drivers={drivers} races={races} />

        <SubTitle>Driver Standings</SubTitle>

        <SeasonLeaderBoard
          driverStandings={latestDriverStandings}
          number={4}
          view="summary"
        />

        <SubTitle>Team Standings</SubTitle>

        <SeasonLeaderBoard
          teamStandings={latestTeamStandings}
          number={4}
          view="summary"
        />

        <PrimaryButton
          text="Season Overview"
          url={`/season/${lastRaceInfo.season}/`}
        />
      </Wrapper>

      { console.log(winsTeamStat)}

      <Wrapper alignment="center" isContainer={true}>
        <Title>Stats</Title>
        <StatBlock
          names={winsDriverStat.map(driver => driver.name)}
          record={winsDriverStat[0].winsTotal}
          title="Most driver wins"
        />
        <StatBlock
          names={winsTeamStat.map(team => team.name)}
          record={winsTeamStat[0].winsTotal}
          title="Most team wins"
        />
      </Wrapper>
    </PageLayout>
  );
};

export const query = graphql`
  query($currentSeason: Int) {
    allDrivers(filter: { season: { eq: $currentSeason } }) {
      nodes {
        code
        name
        nationality
        number
        slug
      }
    }
    allTeams(filter: { season: { eq: $currentSeason } }) {
      nodes {
        name
        nationality
        slug
      }
    }
    allRaces(filter: { season: { eq: $currentSeason } }) {
      nodes {
        name
        round
        season
        hasResults
        winner {
          driver
          team
        }
      }
    }
    allDriverStandings(filter: { season: { eq: $currentSeason } }) {
      nodes {
        season
        round
        driver
        team
        points
        position
        wins
        gainedPoints
        fastestLap
      }
    }
    allTeamStandings(filter: { season: { eq: $currentSeason } }) {
      nodes {
        season
        round
        team
        points
        position
        wins
        gainedPoints
        fastestLap
      }
    }
    winsDriverStat: allDriverStats(filter: { winsRank: { eq: 1 } }) {
      nodes {
        winsRank
        name
        slug
        winsTotal
      }
    }
    winsTeamStat: allTeamStats(filter: { winsRank: { eq: 1 } }) {
      nodes {
          winsRank
          name
          slug
          winsTotal
      }
    }
    nextRace: allRaces(
      filter: { isFuture: { eq: true } }
      limit: 1
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        name
        isFuture
        round
        season
        hasResults
        circuit {
          name
          location {
            city
            country
            lat
            lng
          }
        }
        winner {
          driver
          team
          time
        }
        fastestQualifying {
          times {
            q1
            q2
            q3
          }
          team
          driver
        }
        fastestLap {
          driver
          team
          time
        }
        date
        time
        pole {
          driver
          team
        }
      }
    }
    lastRace: allRaces(
      filter: { isFuture: { eq: false } }
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        name
        round
        isFuture
        season
        hasResults
        circuit {
          name
          location {
            city
            country
            lat
            lng
          }
        }
        winner {
          driver
          team
          time
        }
        fastestQualifying {
          times {
            q1
            q2
            q3
          }
          team
          driver
        }
        fastestLap {
          driver
          team
          time
        }
        date
        time
        pole {
          driver
          team
        }
      }
    }
  }
`;

export default IndexPage;
