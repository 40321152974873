import React from 'react';

import styles from './StatBlock.module.scss';

const StatBlock = ({ names, record, title }) => {
  let name = names;

  if (Array.isArray(names) && names.length > 1) {
    const last = names.pop();
    name = names.join(', ') + ' and ' + last
  }

  return (
    <div className={styles.statBlock}>
      <h1 className={styles.title}>{title}</h1>

      <h2 className={styles.amount}>{record}</h2>

      <p className={styles.names}>{ name }</p>
    </div>
  );
};

export default StatBlock;
