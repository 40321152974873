import React from 'react';
import ReactCountdown, { zeroPad } from 'react-countdown';

import styles from './Countdown.module.scss';

const Countdown = ({ date, time }) => {
  return (
    <ReactCountdown
      date={date + 'T' + time}
      renderer={({ days, hours, minutes }) => (
        <div className={styles.countdown}>
          <h1 className={styles.title}>Race Countdown</h1>
          <div className={styles.clock}>
            <div className={styles.days}>
              {zeroPad(days)} <span>Day{days !== 1 ? 's' : null}</span>
            </div>
            <div className={styles.hours}>
              {zeroPad(hours)} <span>Hour{hours !== 1 ? 's' : null}</span>
            </div>
            <div className={styles.minutes}>
              {zeroPad(minutes)} <span>Min{minutes !== 1 ? 's' : null}</span>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Countdown;
