import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './BaseButton.module.scss';

const BaseButton = ({ className, text, url }) => {
  return (
    <Link className={classNames(styles.button, className)} to={url}>
      {text}
    </Link>
  );
};

export default BaseButton;
