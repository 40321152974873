import React from 'react';
import { CSSTransition } from 'react-transition-group';

import clean from './../../utils/clean';

import styles from './Transition.module.scss';

type EntryTransitionType = 'fadeIn' | 'scale' | 'slideDown' | 'slideUp';

type ExitTransitionType = 'fadeOut' | 'scale' | 'slideDown' | 'slideUp';

type TransitionType = EntryTransitionType | ExitTransitionType;

type TransitionState =
  | 'appear'
  | 'appearDone'
  | 'enter'
  | 'enterDone'
  | 'exit'
  | 'exitDone';

type TransitionProps = {
  children: any;
  delay?: number;
  duration: number;
  show?: boolean;
  entryType?: EntryTransitionType | Array<EntryTransitionType>;
  exitType?: ExitTransitionType | Array<ExitTransitionType>;
};

const Transition = ({
  children,
  delay = 0,
  duration,
  entryType,
  exitType,
  show = true,
}: TransitionProps) => {
  const generateClassNames = (
    state: TransitionState,
    type: TransitionType | Array<TransitionType>
  ) => {
    if (Array.isArray(type)) {
      return type
        .map((type: TransitionType) => styles[`${state}__${type}`])
        .join(' ');
    }
    return styles[`${state}__${type}`];
  };

  return (
    <CSSTransition
      appear={true}
      classNames={clean(
        {
          appear: entryType && generateClassNames('appear', entryType),
          appearActive: entryType && styles['transitionActive'],
          appearDone: entryType && generateClassNames('appearDone', entryType),
          enter: entryType && generateClassNames('enter', entryType),
          enterActive: entryType && styles['transitionActive'],
          enterDone: entryType && styles['enterDone'],
          exit: exitType && generateClassNames('exit', exitType),
          exitActive: exitType && styles['transitionActive'],
        },
        true
      )}
      in={show}
      timeout={duration}
      unmountOnExit
    >
      <div
        className={styles.wrapper}
        style={{
          transitionDelay: `${delay}ms`,
          transitionDuration: `${duration}ms`,
        }}
      >
        {children}
      </div>
    </CSSTransition>
  );
};

Transition.displayName = 'Transition';

export default Transition;
